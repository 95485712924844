@font-face {
    font-family: "Glacial Indifference";
    src: url("./font.ttf");
}

.font {
    font-family: 'Glacial Indifference';
}

.padding-top {
    padding-top: 20px;
}

.padding-left {
    padding-left: 20px;
}

.bg-image {
    background-image: url('texture-3.jpg');
    background-repeat: repeat;
    background-size: 50%;
}


.img-photo-1 {
    margin-top: 30px;
    height: 600px;
}