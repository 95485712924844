.margin-bottom {
  margin-bottom: 60px;
}

.padding-top {
  padding-top: 20px;
}

.no-a-edited {
  text-decoration: none;
}