.img-smaller-float {
  width: 7.6rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 20px;
}

.img-float {
  width: 15rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 20px;
}

.img-float-bigger {
  width: 20rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 20px;
}

.img-float-way-bigger {
  width: 30rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 20px;
}

.code {
  display: block;
  width: 90%;
  min-height: 5em;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: monospace;
  border: 1px solid #bbb;
  padding: 1em;
  white-space:pre-wrap;
}